import discipline from '../utils/discipline';

const edizioni = [
  {
    value: '2020',
    label: '2020'
  },
  {
    value: '2021',
    label: '2021'
  },
  {
    value: '2022',
    label: '2022'
  },
  {
    value: '2023',
    label: '2023'
  },
  {
    value: '2024',
    label: '2024'
  }
];

const sortByLabel = (a, b) => (a.label < b.label ? -1 : 1);
export const facets = [
  {
    id: 'fruizione',
    name: 'Fruizione',
    match: (item, value) =>
      !value || (value === 'free' && item.free) || (value === 'abbonamento' && !item.free),
    extract: (item) =>
      item.free
        ? {
            label: 'Contenuti gratuiti',
            value: 'free'
          }
        : {
            label: 'Contenuti in abbonamento',
            value: 'abbonamento'
          },
    sort: sortByLabel
  },
  {
    id: 'disciplina',
    name: 'Disciplina',
    match: (item, value) => !value || item.disciplina === value,
    extract: (item) => discipline.find((elem) => elem.value === item.disciplina),
    sort: sortByLabel
  },
  {
    id: 'relatore',
    name: 'Relatori',
    match: (item, value) =>
      !value || item.relatori?.some((relatore) => relatore.idrelatore === value),
    extract: (item) =>
      item.relatori.map((relatore) => ({
        value: relatore.idrelatore,
        label: `${relatore.nome} ${relatore.cognome}`,
        sort: relatore.cognome
      })),
    sort: (a, b) => (a.sort < b.sort ? -1 : 1)
  },
  // {
  //   id: 'tag',
  //   name: 'Tag',
  //   match: (item, value) =>
  //     !value || item.parole_chiave?.split('#').some((item) => item.split('&')[1] === value),
  //   extract: (item) =>
  //     item.parole_chiave?.split('#').map((item) => {
  //       const [label, value] = item.split('&');
  //       return {
  //         label,
  //         value
  //       };
  //     }),
  //   sort: sortByLabel
  // },
  {
    id: 'tipologia',
    name: 'Tipologia',
    match: (item, value) =>
      !value ||
      (value === 'conf' && item.t_c) ||
      (value === 'spet' && item.t_s) ||
      (value === 'special' && item.genere === 'Evento speciale'),
    extract: (item) => {
      if (item.genere === 'Evento speciale') {
        return {
          label: 'Evento speciale',
          value: 'special'
        };
      }
      if (item.t_c) {
        return {
          label: 'Conferenze',
          value: 'conf'
        };
      }
      if (item.t_s) {
        return {
          label: 'Spettacoli',
          value: 'spet'
        };
      }
    },
    sort: sortByLabel
  },
  {
    id: 'edizione',
    name: 'Edizione',
    match: (item, value) => !value || item.origine === value,
    extract: (item) => edizioni.find((elem) => elem.value === item.origine),
    sort: sortByLabel
  }
  // {
  //   id: 'serie',
  //   name: 'Raccolte',
  //   match: (item, value) => !value || item.serie_evento?.some((elem) => elem.id === value),
  //   extract: (item) =>
  //     item.serie_evento?.map((elem) => ({
  //       label: elem.titolo,
  //       value: elem.id
  //     })),
  //   sort: sortByLabel
  // }
];

export const updateQuery = (name, active, facet, query) => {
  const currentValue = query[facet];
  const newQuery = Object.assign({}, query);
  if (currentValue) {
    if (active) {
      if (Array.isArray(currentValue)) {
        newQuery[facet] = currentValue.concat(name);
      } else {
        newQuery[facet] = [currentValue].concat(name);
      }
    } else {
      if (Array.isArray(currentValue)) {
        newQuery[facet] = currentValue.filter((item) => item !== name);
        if (newQuery[facet].length === 1) {
          newQuery[facet] = newQuery[facet][0];
        }
      } else {
        if (currentValue === name) {
          delete newQuery[facet];
        } else {
          // ignore
        }
      }
    }
  } else {
    if (active) {
      newQuery[facet] = name;
    } else {
      // ignore
    }
  }
  return newQuery;
};
