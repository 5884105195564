import React, { useState, useEffect, useCallback } from 'react';
import { Location } from '@reach/router';
import { ReactComponent as User } from '../images/icons/user-light.svg';
import { useAuth } from '../context/auth';
import Link from './link';
import logo from '../images/logo-festival-online-conferenze.png';
import logoMobile from '../images/logo-festival-online-conferenze-mobile.png';
import simbolo from '../images/simbolo-festival-online.png';
const Header = ({ location }) => {
  const { user, userData, signOut } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = useCallback(() => setIsOpen((value) => !value), []);
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('pushmenu__body--open');
    } else {
      document.body.classList.remove('pushmenu__body--open');
    }
  }, [isOpen]);

  // Chiudi il menu al cambio di rotta
  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  // Chiudi il menu al logout (o al login)
  useEffect(() => {
    setIsOpen(false);
  }, [user]);

  return (
    <header className={`header`}>
      <div className="header__topbar d-lg-none">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <button
                onClick={toggleMenu}
                className={`hamburger${isOpen ? ' hamburger--open' : ''}`}
              >
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 px-1 px-lg-3">
            <div className="header__content w-100 justify-content-between d-inline-flex align-items-end">
              <Link to="/" aria-label="Home" className="header__logo">
                <picture>
                  <source media="(max-width: 1023px)" srcSet={logoMobile} />
                  <img src={logo} alt="Logo" />
                </picture>
              </Link>
              <img src={simbolo} className="header__simbolo d-none d-lg-inline-block" alt="" />
            </div>
          </div>
        </div>
      </div>
      <nav className="navigation pushmenu__sidebar">
        <div className="container">
          <div className="row">
            <div className="col-12 px-0 px-lg-3 d-flex flex-column flex-lg-row align-items-center">
              {!userData && (
                <>
                  <Link to="/" className="mr-lg-2 mr-lg-4 navigation__link">
                    Home
                  </Link>
                  <Link
                    to="/festival-2024"
                    className="mr-lg-2 mr-lg-4 navigation__link"
                    activeClassName="navigation__link--active"
                  >
                    Festival 2024
                  </Link>
                  <Link
                    to="/tutti-video"
                    className="mr-lg-2 mr-lg-4 navigation__link"
                    activeClassName="navigation__link--active"
                  >
                    Catalogo video
                  </Link>
                </>
              )}
              {userData && (
                <>
                  <Link
                    to="/"
                    className="mr-lg-2 mr-lg-4 navigation__link"
                    exactActive={true}
                    activeClassName="navigation__link--active"
                  >
                    Home
                  </Link>
                  <Link
                    to="/festival-2024"
                    className="mr-lg-2 mr-lg-4 navigation__link"
                    activeClassName="navigation__link--active"
                  >
                    Festival 2024
                  </Link>
                  <Link
                    to="/tutti-video"
                    className="mr-lg-2 mr-lg-4 navigation__link"
                    activeClassName="navigation__link--active"
                  >
                    Catalogo video
                  </Link>
                  <Link
                    to="/faq"
                    className="mr-lg-2 mr-lg-4 navigation__link"
                    activeClassName="navigation__link--active"
                  >
                    FAQ
                  </Link>
                  {user ? (
                    <Link
                      to="/i-miei-dati"
                      className="mr-lg-2 mr-lg-4 navigation__link"
                      activeClassName="navigation__link--active"
                    >
                      I miei dati
                    </Link>
                  ) : null}
                  {!user ||
                  !userData ||
                  !userData.abbonamento ||
                  (userData.abbonamento && userData.expireDate) ? (
                    <Link
                      to="/accesso"
                      className="mr-lg-2 mr-lg-4 navigation__link"
                      activeClassName="navigation__link--active"
                    >
                      Accesso
                    </Link>
                  ) : null}
                </>
              )}
              {user ? (
                <button onClick={signOut} className="ml-lg-auto navigation__user">
                  <User /> Esci
                </button>
              ) : (
                <Link to="/login" className="ml-lg-auto navigation__user">
                  <User /> Entra
                </Link>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

const HeaderWrapper = (props) => {
  return <Location>{({ location }) => <Header {...props} location={location} />}</Location>;
};

export default HeaderWrapper;
